import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from "../Account/UserContext";
export const SideMenu = () => {

  const userContext = useContext(UserContext);
  return (<div className="js-scrollbar u-sidebar__body" >
    <header className="d-flex align-items-center u-sidebar--account__holder mt-0 justify-content-center" style={{ "backgroundColor": "#f0f2ff" }}>
      {/* <div className="position-relative">
        <img className="u-sidebar--account__holder-img mCS_img_loaded" src="../../assets/img/100x100/img1.jpg" alt="Image Description" />
        <span className="badge badge-xs badge-outline-success badge-pos rounded-circle" />
      </div> */}
      <div className="pr-4">   
        <img className="card-img-top" src={require("../../assets/img/Cobble Logo Squarish.png")}  alt="Image Description" style={{"height":"35px", "width" :"35px", "object-fit": "cover"}}/>

      </div>
      <div className="">
        <span className="font-weight-semi-bold">{userContext.user.firstName} {userContext.user.lastName}<span className="badge badge-success ml-1">Pro</span></span>
        <span className="u-sidebar--account__holder-text">Property Owner</span>
      </div>
    </header>

    <div className="u-sidebar__content--account">

      <ul className="list-unstyled u-sidebar--account__list">
        <li className="u-sidebar--account__list-item">
          <Link className="u-sidebar--account__list-link" to="/">
            <span className="fas fa-home u-sidebar--account__list-icon mr-2" />
            Dashboard
            </Link>
        </li>
        <li className="u-sidebar--account__list-item">
          <Link className="u-sidebar--account__list-link" to="/properties">
            <span className="fas fa-cubes u-sidebar--account__list-icon mr-2" />
            Manage Properties
          </Link>
        </li>
        <li className="u-sidebar--account__list-item">
          <Link className="u-sidebar--account__list-link" to="/account">
            <span className="fas fa-user-circle u-sidebar--account__list-icon mr-2" />
            Profile
          </Link>
        </li>
        {/* <li className="u-sidebar--account__list-item">
          <Link className="u-sidebar--account__list-link" to="/createTicket">
            <span className="fas fa-cubes u-sidebar--account__list-icon mr-2" />
            New Maintenance Request
          </Link>
        </li> */}
      </ul>

      <div className="u-sidebar--account__list-divider" />

      <ul className="list-unstyled u-sidebar--account__list">
        <li className="u-sidebar--account__list-item">
          <Link className="u-sidebar--account__list-link" to="/invite">
            <span className="fas fa-user-plus u-sidebar--account__list-icon mr-2" />
            Invite friends
            </Link>
        </li>
        <li className="u-sidebar--account__list-item">
          <Link className="u-sidebar--account__list-link" to="/signout">
            <span className="fas fa-key u-sidebar--account__list-icon mr-2" />
            Sign out
            </Link>
        </li>
      </ul>

      <footer id="SVGwaveWithDots" className="u-sidebar__footer u-sidebar__footer--account" style={{}}>
        <ul className="list-inline mb-0">
          <li className="list-inline-item pr-3">
            <a className="u-sidebar__footer--account__text" href="/">Privacy</a>
          </li>
          <li className="list-inline-item pr-3">
            <a className="u-sidebar__footer--account__text" href="/">Terms</a>
          </li>
          <li className="list-inline-item">
            <a className="u-sidebar__footer--account__text" href="mailto:support@cobble.ai">
              <i className="fas fa-info-circle" />
            </a>
          </li>
        </ul>

        <div className="position-absolute right-0 bottom-0 left-0">
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 126.5" style={{ marginBottom: '-7px', enableBackground: 'new 0 0 300 126.5' }} xmlSpace="preserve" className="injected-svg js-svg-injector" data-parent="#SVGwaveWithDots">
            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t.wave-bottom-with-dots-0{fill:#377DFF;}\n\t.wave-bottom-with-dots-1{fill:#377DFF;}\n\t.wave-bottom-with-dots-2{fill:#DE4437;}\n\t.wave-bottom-with-dots-3{fill:#00C9A7;}\n\t.wave-bottom-with-dots-4{fill:#FFC107;}\n" }} />
            <path className="wave-bottom-with-dots-0 fill-primary" opacity=".6" d="M0,58.9c0-0.9,5.1-2,5.8-2.2c6-0.8,11.8,2.2,17.2,4.6c4.5,2.1,8.6,5.3,13.3,7.1C48.2,73.3,61,73.8,73,69  c43-16.9,40-7.9,84-2.2c44,5.7,83-31.5,143-10.1v69.8H0C0,126.5,0,59,0,58.9z" />
            <path className="wave-bottom-with-dots-1 fill-primary" d="M300,68.5v58H0v-58c0,0,43-16.7,82,5.6c12.4,7.1,26.5,9.6,40.2,5.9c7.5-2.1,14.5-6.1,20.9-11  c6.2-4.7,12-10.4,18.8-13.8c7.3-3.8,15.6-5.2,23.6-5.2c16.1,0.1,30.7,8.2,45,16.1c13.4,7.4,28.1,12.2,43.3,11.2  C282.5,76.7,292.7,74.4,300,68.5z" />
            <g>
              <circle className="wave-bottom-with-dots-2 fill-danger" cx="259.5" cy={17} r={13} />
              <circle className="wave-bottom-with-dots-1 fill-primary" cx={290} cy="35.5" r="8.5" />
              <circle className="wave-bottom-with-dots-3 fill-success" cx={288} cy="5.5" r="5.5" />
              <circle className="wave-bottom-with-dots-4 fill-warning" cx="232.5" cy={34} r={2} />
            </g>
          </svg>
        </div>

      </footer>
    </div>
  </div>);
};
