import React, { Component, useState, useEffect } from 'react';
import ReactStreetview from 'react-streetview';
import SearchAddress from '../SearchAddress';
const SearchBar2 = () =>{

    useEffect(() => {
            // initialization of unfold component
        window.$.HSCore.components.HSUnfold.init(window.$('[data-unfold-target]'), {
        afterOpen: function () {
            window.$(this).find('input[type="search"]').focus();
        }
        });
    }, []);
    return (
        <div>
            {/* <!-- Search Content --> */}
<div id="searchSlideDown" className="dropdown-unfold u-search-slide-down" aria-labelledby="searchSlideDownInvoker" style={{"position":"fixed", "max-width":"100vw"}}>
  <form>
    {/* <!-- Input Group --> */}
    <div className="input-group input-group-borderless u-search-slide-down__input rounded mb-2">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <span className="fas fa-search"></span>
        </span>
      </div>
      <input type="search" className="form-control" placeholder="Search Front" aria-label="Search Front"/>
      <div className="input-group-append">
        <a className="input-group-text" href="javascript:;"
           aria-label="close"
           data-unfold-event="click"
           data-unfold-hide-on-scroll="false"
           data-unfold-target="#searchSlideDown"
           data-unfold-type="css-animation"
           data-unfold-animation-in="active"
           data-unfold-animation-out="fadeOutUp"
           data-unfold-delay="0"
           data-unfold-duration="800"
           data-unfold-overlay='{
            "className": "u-search-slide-down-bg-overlay",
            "background": "rgba(55, 125, 255, .1)",
            "animationSpeed": 400
           }'>
          <span className="fas fa-times" aria-hidden="true"></span>
        </a>
      </div>
    </div>
    {/* <!-- End Input Group -->

    <!-- Suggestions Content --> */}
    <div className="rounded bg-white u-search-slide-down__suggestions py-3 px-5">
      <ul className="list-group list-group-flush list-group-borderless mb-0">
        <li><a className="list-group-item list-group-item-action" href="../pages/about-agency.html">About Front</a></li>
        <li><a className="list-group-item list-group-item-action" href="../../starter/index.html">Getting Started</a></li>
        <li><a className="list-group-item list-group-item-action" href="../../documentation/index.html">Documentation</a></li>
      </ul>
    </div>
    {/* <!-- End Suggestions Content --> */}
  </form>
</div>
{/* <!-- End Search Content -->

<!-- Search --> */}
<div className="position-relative">
  <a id="searchSlideDownInvoker" className="btn btn-sm btn-icon btn-light u-search-slide-down-trigger target-of-invoker-has-unfolds" href="javascript:;" role="button"
     aria-haspopup="true"
     aria-expanded="false"
     aria-controls="searchSlideDown"
     data-unfold-type="css-animation"
     data-unfold-hide-on-scroll="false"
     data-unfold-target="#searchSlideDown"
     data-unfold-animation-in="active"
     data-unfold-animation-out="fadeOutUp"
     data-unfold-delay="0"
     data-unfold-duration="800"
     data-unfold-overlay='{
      "className": "u-search-slide-down-bg-overlay",
      "background": "rgba(55, 125, 255, .1)",
      "animationSpeed": 400
     }'>
    <span className="fas fa-search btn-icon__inner u-search-slide-down-trigger__icon"></span>
  </a>
</div>
{/* <!-- End Search --> */}
        </div>
    );
}
const SearchBar = (props) =>{
    return (
    <form className="form-inline input-group input-group-sm shadow-sm flex-nowrap">
        <div className="input-group-prepend">
        <span className="input-group-text" id="docsSearch">
            <span className="fas fa-search"></span>
        </span>
        </div>
        {/* <input className="js-hs-docs-search form-control ui-autocomplete-input" type="text" placeholder="Search..." aria-label="Search..." aria-describedby="docsSearch" data-url="../assets/include/ajax/autocomplete-data-for-documentation-search.json"/> */}
        <SearchAddress placeholder={"Enter an address"} {...props}></SearchAddress>
        <ul id="ui-id-1" tabIndex="0" className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front" style={{"display": "none"}}></ul>
    </form>
    );
}
export default SearchBar;