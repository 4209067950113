import axios from 'axios';
import { useAuth0 } from "../../react-auth0-spa";
export const PropertyProvider = () => {
    const { getTokenSilently } = useAuth0();
    const saveProperty = async (id, property, tenant) => {
        const token = await getTokenSilently();
        if (property.id && false) {
            //update
        }
        else {
            //new
            // for later: https://medium.com/@trekinbami/using-environment-variables-in-react-6b0a99d83cf5
            let url = `${process.env.REACT_APP_SERVER_URL}/properties`;
            let paylod =  { property, tenant };
            if(property.id)
                paylod['id'] = property.id;
            return axios.post(url, paylod,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }).then(res => {
                    console.log("success!");
                }, error => {
                    let errorMessage = "";
                    error.response.data.details.forEach((item) => { errorMessage += item.message + "\n" });
                    throw errorMessage;
                });
        }
    };
    const deleteProperty = async (property) => {
        const token = await getTokenSilently();
        let url = `${process.env.REACT_APP_SERVER_URL}/properties`;
        try {
            let result = await axios.delete(url, property, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            });
            return result;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    const getProperty = async (propertyId) => {

        const token = await getTokenSilently();
        let url = `${process.env.REACT_APP_SERVER_URL}/properties`;
        try {
            let result = await axios.get(url, {
                params: { id: propertyId },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            });
            return result.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }
    const getProperties = async () => {
        const token = await getTokenSilently();
        // about env variables: https://medium.com/@trekinbami/using-environment-variables-in-react-6b0a99d83cf5
        let url = `${process.env.REACT_APP_SERVER_URL}/properties`;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(res => {
                console.log("success!");
                return res;
            }, error => {
                console.log(error);
                throw error;
            });
    };
    return {
        saveProperty,
        deleteProperty,
        getProperties,
        getProperty
    };
};
