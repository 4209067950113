import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const PropertyCard = (props) => {
    const [containerName, setContainerName] = useState("");
    useEffect(() => {
        setContainerName(props.containerName);
    }, [props.cardName]);
    const deleteProperty = async () => {
        try {
            if (props.deleteProperty){
                let result = await props.deleteProperty(props.property);
                console.log("completed delete " + result);
            }
        }
        catch {
            console.log("error in delete");

        }
    }
    const editProperty = async () => {
        
        props.history.push('/properties/edit/' + props.property.id, {'property' : props.property});
    }
    return (<div className="card" >
        <div className="card-header card-collapse" id={"header" + props.containerName}>
            <h5 className="mb-0">
                <button className="btn btn-link btn-block card-btn collapsed p-3" role="button" data-toggle="collapse" data-target={"#" + props.containerName} aria-expanded="false" aria-controls={props.containerName}>
                    <span className="row align-items-center">
                        <span className="col-md-6 mb-2 mb-md-0">
                            <span className="media align-items-center">
                                <span className="max-width-9 mr-3 h3">
                                    <span className="fas fa-home small" />

                                </span>
                                <span className="media-body">
                                    <span className="font-size-1 pr-2">{props.property.property.address1}</span>
                                    <br />

                                    {props.property.property.address2 &&

                                        <span className="font-size-1">
                                            {props.property.property.address2}
                                        </span>}
                                </span>
                            </span>
                        </span>
                        {/* <span className="col-4 col-md-2 text-md-right">
                            <span className="btn btn-xs btn-soft-warning btn-pill">Primary</span>
                        </span> */}
                        <span className="col-10 col-md-3 pt-2 pt-md-0">
                            <span className="d-block font-size-1">Last Edited: {
                                new Intl.DateTimeFormat('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: '2-digit'
                                }).format(props.property.dateModified)
                            }</span>
                        </span>
                        <span className="col-2 col-md-1 text-right">
                            <span className="card-btn-arrow">
                                <span className="fas fa-arrow-down small" />
                            </span>
                        </span>
                    </span>
                </button>
            </h5>
        </div>
        <div id={props.containerName} className="collapse" aria-labelledby={"header" + props.containerName} data-parent="#propertyList">
            <div className="card-body px-4">
                {/* Card Details */}
                <div className="row  justify-content-center">
                    {props.property.tenant ?
                        <div className="col-sm-7 mb-2 mb-sm-0">
                            <h4 className="h6 mb-1">{props.property.tenant.firstName} {props.property.tenant.lastName}</h4>
                            <span className="d-block font-size-1 mb-1">{props.property.tenant.phone}</span>
                            <span className="d-block font-size-1 mb-1">{props.property.tenant.email}</span>

                        </div> : <div />}
                    <div className="col-sm-5 mb-2 mb-sm-0">
                        <h5 className="h6">{props.property.property.address1}</h5>
                        <address className="font-size-1">
                            {props.property.property.address2 &&
                                <div>
                                    {props.property.property.address2} <br />
                                </div>}
                            {props.property.property.city}, {props.property.property.state}, {props.property.property.postalCode}
                        </address>
                        {props.isEditing ?

                            (<div className="">
                                <button class="btn btn-primary btn-sm-wide btn-pill transition-3d-hover" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            </div>) : <div>
                                {/* <button type="button" className="btn btn-sm btn-soft-secondary mr-1 transition-3d-hover" onClick={deleteProperty}>Delete</button>
                                <button type="button" className="btn btn-sm btn-primary transition-3d-hover" onClick={editProperty}>Edit</button> */}

                            </div>
                        }
                    </div>
                </div>
                {/* End Card Details */}
            </div>
        </div>
    </div>);
}

export default withRouter(PropertyCard);