import React, { Component, useEffect } from 'react';
import {  withRouter } from 'react-router-dom';

import { useAuth0 } from "../../react-auth0-spa";

const Signout = (props) => {

  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    useEffect(() =>{
        logout();
        props.history.push("/")
    });
    return (<div></div>)
}

export default withRouter(Signout);