import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
const CreateTicket = (props) => {
  const [user, setUser] = useState({ name: "Wilson", address: "123 Maple" });
  const goBack = () => {
    props.history.goBack();
  }
  return (
    <div className="d-flex justify-content-center">

      <form className="card mb-5">
        {/* Header */}
        <header className="card-header  py-3 px-5">
          <div className="row justify-content-between align-items-center no-gutters">
            <div >
              <h4 className="h6 mb-0">Report a service request</h4>
            </div>
            <div className="col-9 text-right">

            </div>
          </div>
        </header>
        {/* End Header */}
        <div className="card-body pt-3 pb-5 px-5">
          {/* Project Title */}
          <div className="mb-2">
            <div className="input-group">
              <input className="form-control" type="text" placeholder="Enter a short description" aria-label="Enter a short description" />
            </div>
          </div>

          {/* End Project Title */}

          {/* Input */}
          <textarea className="form-control" rows={8} placeholder="Tell us about what's going on" aria-label="Tell us about what's going on" defaultValue={""} />
          {/* End Input */}
          <hr className="my-4" />


          {/* File Attachment Input */}
          <label className="file-attachment-input mb-4 d-none d-md-block" htmlFor="fileAttachmentInput">
            Browse your device and upload documents
                    <small className="d-block text-muted">Maximum file size 10MB</small>
            <input id="fileAttachmentInput" name="file-attachment" type="file" className="file-attachment-input__label" />
          </label>
          {/* End File Attachment Input */}

          {/* File Attachment Button */}
          <label className="btn btn-sm btn-primary transition-3d-hover file-attachment-btn" htmlFor="fileAttachmentBtn">
            Choose file to upload
                    <input id="fileAttachmentBtn" name="file-attachment" type="file" className="file-attachment-btn__label" />
          </label>
          {/* End File Attachment Button */}
          {/* Buttons */}
          <div className="d-flex justify-content-end pt-4">
            <button type="submit" className="btn btn-sm btn-primary transition-3d-hover mr-1">Submit</button>
            <button type="submit" className="btn btn-sm btn-soft-secondary transition-3d-hover" onClick={goBack}>Cancel</button>
          </div>
          {/* End Buttons */}
        </div>
      </form>

    </div>
  );
}

export default withRouter(CreateTicket);