import React, { useEffect, useState } from 'react';
import SearchBar from '../Map/SearchBar';
import states from './states.json';
import { PropertyProvider } from './propertyProvider';
import { withRouter } from 'react-router-dom'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
// import Loader from 'react-loader-spinner'
import InputMask from 'react-input-mask';
import "../../assets/css/checkbox.css";
import {StripeProvider} from 'react-stripe-elements';

const PropertyDetailFormInternal = (props) => {
    const defaultProperty = { address1: '', address2: '', city: '', state: '', streetNumber: '', postalCode: '', isOccupied: true };
    const defaultTenant = { firstName: '', lastName: '', email: '', phone: '', };
    const { saveProperty, getProperty } = PropertyProvider();
    const [id, setId] = useState('');
    const [property, setProperty] = useState(defaultProperty);
    const [tenant, setTenant] = useState(defaultTenant);
    const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {
    //     if (props.property)
    //         setProperty(props.property);
    // }, [props.property]);

    useEffect(() => {
        const loadData = async () => {
            try {
                let loaded_property = await getProperty(props.match.params.id);
                setProperty(loaded_property.property);
                setTenant(loaded_property.tenant);

            }
            catch (err) {
                alert(err);
            }
        };

        if (!props.match.params.id) {
            // setProperty(defaultProperty);
        }
        else {
            setIsLoading(true);
            loadData().then(result => {
                setIsLoading(false);
            });
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (props.propertyUpdated)
            props.propertyUpdated(property);
    }, [property]);


    const placeChanged = (addressResults) => {
        let newProperty = { ...property };

        newProperty['address1'] = addressResults ? `${addressResults.streetNumber} ${addressResults.streetName}` : '';
        newProperty['state'] = addressResults ? addressResults.state : '';
        newProperty['city'] = addressResults ? addressResults.city : '';
        newProperty['postalCode'] = addressResults ? addressResults.postalCode : '';
        setProperty(newProperty)
        console.log(addressResults);
    }
    const onValueChanged = (ele) => {

        if (ele.target.getAttribute("data-obj") == 'property') {
            let newProperty = { ...property };
            newProperty[ele.target.name] = ele.target.value;
            setProperty(newProperty);
        }
        if (ele.target.getAttribute("data-obj") == 'tenant') {
            let newTenant = { ...tenant };
            newTenant[ele.target.name] = ele.target.value;
            setTenant(newTenant);
        }
    }
    const onOccupiedChanged = (value) => {
        if(tenant == null)
            setTenant(defaultTenant);
        setProperty({ ...property, isOccupied: !property.isOccupied });
    }
    const onSubmit = async (event) => {
        event.preventDefault();
        if (event.target.checkValidity() === false)
            return;
        setIsLoading(true);
        try {
            let response = await saveProperty(id, property, tenant);

            setIsLoading(false);
            props.history.push("/properties");
        }
        catch (err) {
            setIsLoading(false);
            alert(err);
        }
        finally {
        }
    }
    return (
        <div>
            <form className="js-validate" onSubmit={onSubmit}>
                <div className="container">
                    <div className="card card-frame mb-6">
                        <div className="card-body">
                            {/* Title */}
                            <div className="text-center mb-4 ">
                                <h3 className="h4">Property Info</h3>
                            </div>
                            {/* End Title */}
                            <div className="border-bottom pb-7 mb-3">
                                <div className="row">

                                    <div className="col-md-8 mx-auto">
                                        <div className="pb-6">

                                            <div className="js-form-message">
                                                <label className="form-label">
                                                    Search Address
                                                <span className="text-danger">*</span>
                                                </label>
                                                <SearchBar placeChanged={placeChanged}></SearchBar>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 pb-3">
                                    <p>Or</p>
                                </div>
                                {/* Property Form */}
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* Input */}
                                        <div className="js-form-message mb-6">
                                            <label className="form-label">
                                                Address 1
                                            <span className="text-danger">*</span>
                                            </label>
                                            <input type="text" className="form-control" name="address1" placeholder="123 Main Street" aria-label="123 Main Street" required data-msg="Please enter a valid address." data-error-class="u-has-error" data-success-class="u-has-success"
                                                value={property.address1} onChange={onValueChanged} data-obj="property" />
                                        </div>
                                        {/* End Input */}
                                    </div>
                                    <div className="col-md-12">
                                        {/* Input */}
                                        <div className="js-form-message mb-6">
                                            <label className="form-label">
                                                Address 2
                </label>
                                            <input type="text" className="form-control" name="address2" placeholder="Apt, Suite, etc." aria-label="Apt, Suite, etc." data-msg="" data-error-class="u-has-error" data-success-class="u-has-success"
                                                value={property.address2} onChange={onValueChanged} data-obj="property" />
                                        </div>
                                        {/* End Input */}
                                    </div>
                                    <div className="col-md-6">
                                        {/* Input */}
                                        <div className="js-form-message mb-6">
                                            <label className="form-label">
                                                City
                  <span className="text-danger">*</span>
                                            </label>
                                            <input type="text" className="form-control" name="city" placeholder="Houston" aria-label="Houston" required data-msg="Please enter a valid address." data-error-class="u-has-error" data-success-class="u-has-success"
                                                value={property.city} onChange={onValueChanged} data-obj="property" />
                                        </div>
                                        {/* End Input */}
                                    </div>

                                    <div className="col-md-3">
                                        {/* Input */}
                                        <div className="js-form-message mb-6">
                                            <label className="form-label">
                                                State
                                            <span className="text-danger">*</span>
                                            </label>
                                            <select className="custom-select  mb-3" onChange={onValueChanged} value={property.state} name="state" data-obj="property">
                                                <option defaultValue key="default">State</option>
                                                {Object.getOwnPropertyNames(states).map((state, i) => {
                                                    // Return the element. Also pass key     
                                                    return (<option value={state} key={i}>{state}</option>)
                                                })}
                                            </select>
                                        </div>
                                        {/* End Input */}
                                    </div>

                                    <div className="col-md-3">
                                        {/* Input */}
                                        <div className="js-form-message mb-6">
                                            <label className="form-label">
                                                Zipcode
                  <span className="text-danger">*</span>
                                            </label>
                                            <InputMask mask="99999" maskChar=" " type="text" className="form-control" name="postalCode" placeholder="77777" aria-label="77777" required data-msg="Please enter your property's zipcode." data-error-class="u-has-error" data-success-class="u-has-success" value={property.postalCode} onChange={onValueChanged} data-obj="property" />

                                        </div>
                                        {/* End Input */}
                                    </div>
                                    <div className="w-100" />

                                </div>
                                {/* End Billing Form */}
                            </div>
                            {/* Tenant checkbox */}
                            <div className="pb-3 mb-3  row">


                                <div className="checkbox-container circular-container col-12">
                                    <label className="checkbox-label">
                                        <input type="checkbox" checked={property.isOccupied} onChange={onOccupiedChanged} />
                                        <span className="checkbox-custom circular" />
                                    </label>
                                    <label class="input-title" >
                                        <span class="d-block">Is there currenlty a tenant?</span>
                                        <small class="d-block text-muted">We can help find you the right tenant</small>
                                    </label>
                                </div>


                            </div>
                            {/* Tenant Info */}
                            {property.isOccupied ?
                                <div className="mb-7 pt-7 border-top">
                                    {/* Title */}
                                    <div className="mb-4">
                                        <h2 className="h4">Tenant Info</h2>
                                    </div>
                                    {/* End Title */}
                                    {/* Input */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* Input */}
                                            <div className="js-form-message mb-6">
                                                <label className="form-label">
                                                    First name
                  <span className="text-danger">*</span>
                                                </label>
                                                <input type="text" className="form-control" name="firstName" placeholder="First" aria-label="First" required data-msg="Please enter your frist name." data-error-class="u-has-error" data-success-class="u-has-success"
                                                    value={tenant.firstName} onChange={onValueChanged} data-obj="tenant" />
                                            </div>
                                            {/* End Input */}
                                        </div>
                                        <div className="col-md-6">
                                            {/* Input */}
                                            <div className="js-form-message mb-6">
                                                <label className="form-label">
                                                    Last name
                  <span className="text-danger">*</span>
                                                </label>
                                                <input type="text" className="form-control" name="lastName" placeholder="Last" aria-label="Last" required data-msg="Please enter your last name." data-error-class="u-has-error" data-success-class="u-has-success"
                                                    value={tenant.lastName} onChange={onValueChanged} data-obj="tenant" />
                                            </div>
                                            {/* End Input */}
                                        </div>
                                        <div className="w-100" />
                                        <div className="col-md-6">
                                            {/* Input */}
                                            <div className="js-form-message mb-6">
                                                <label className="form-label">
                                                    Email address
                  <span className="text-danger">*</span>
                                                </label>
                                                <input type="email" className="form-control" name="email" placeholder="email@gmail.com" aria-label="email@gmail.com" required data-msg="Please enter a valid email address." data-error-class="u-has-error" data-success-class="u-has-success"
                                                    value={tenant.email} onChange={onValueChanged} data-obj="tenant" />
                                            </div>
                                            {/* End Input */}
                                        </div>
                                        <div className="col-md-6">
                                            {/* Input */}
                                            <div className="js-form-message mb-6">
                                                <label className="form-label">
                                                    Phone
                </label>
                                                <InputMask mask="999 999 9999" maskChar=" " type="text" className="form-control" name="phone" placeholder="555 555 5555" aria-label="555 555 5555" required data-msg="Please enter your tenant's phone number." data-error-class="u-has-error" data-success-class="u-has-success" value={tenant.phone} onChange={onValueChanged} data-obj="tenant" />


                                            </div>
                                            {/* End Input */}
                                        </div>
                                        <div className="w-100" />
                                    </div>

                                </div> : <div />}
                            {/* End Tenant Info */}

                            {/* Button */}
                            <div>
                                {isLoading ?

                                    (<div className="d-flex justify-content-end align-items-center">
                                        <button class="btn btn-primary btn-sm-wide btn-pill transition-3d-hover" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                    </button>
                                    </div>) :
                                    (<div className="d-flex justify-content-between align-items-center">
                                        <a href="#" onClick={() => { props.history.push("/properties") }}><small className="mr-2" /> Cancel</a>
                                        <button type="submit" className="btn btn-primary btn-sm-wide btn-pill transition-3d-hover">{props.submitText}</button>
                                    </div>)

                                }
                            </div>
                            {/* End Button */}
                        </div>
                    </div>

                </div>
            </form >

        </div >);
};



const PropertyDetailForm = (props) => {
    return (
      <StripeProvider apiKey="pk_test_12345">
        <PropertyDetailFormInternal {...props} />
      </StripeProvider>
    );
  };
 
  
export default withRouter(PropertyDetailForm);