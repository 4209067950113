import React, { useEffect, useContext } from 'react';
import Page404 from '../Error404';
import CreateTicket from '../Ticket/Create';
import DashboardLanding from './Landing';
import { Route, Switch } from 'react-router-dom';
import { SideMenu } from './SideMenu';
import { Properties } from '../Properties/Index';
import Account from '../Account/Index';
import PropertyDetailForm from '../Properties/PropertyDetailForm.js';
import { PropDetail } from '../Properties/Detail';
import Signout from '../Auth/Signout';
import Invite from "../Invite/Index";
import { UserContext, UserContextProvider } from "../Account/UserContext";

import Loader from 'react-spinners/PropagateLoader';
import { css } from '@emotion/core';

function DashboardContent() {

  const userContext = useContext(UserContext);
  useEffect(() => {

    setTimeout(() => {
      window.$.HSCore.components.HSUnfold.init(window.$('[data-unfold-target]'));
    }, 10);
  });
  return (
    <div>
      {/* ========== MAIN ========== */}
      <main id="content" role="main">
        {/* Content Section */}
        <div className="d-flex  bg-light">
          <div className="row w-100 px-0 mx-0" style={{ "minHeight": "100vh" }}>

            <div className="d-none d-md-block col-0 col-md-3 px-0">

              {/* Content */}
              <SideMenu></SideMenu>
            </div>
            <div className="justify-content-center col-12 col-md-9 px-0 mx-0">
              <div style={{ "borderBottom": "1px solid #c3c1c1" }} className="container">
                {/* Breadcrumb Section */}
                <div className="space-top-1 pb-3  ml-3 pl-5" >
                  <div className="row">
                    <div className="col-5 order-2 mb-4 mb-lg-0 d-flex justify-content-end px-0 mx-0">

                      {/* Account Sidebar Toggle Button */}
                      <a id="sidebarNavToggler" className="btn btn-text-primary d-block d-md-none" href="javascript:;" role="button" aria-controls="sidebarContent" aria-haspopup="true" aria-expanded="false" data-unfold-event="click" data-unfold-hide-on-scroll="false" data-unfold-target="#sidebarContent" data-unfold-type="css-animation" data-unfold-animation-in="fadeInLeft" data-unfold-animation-out="fadeOutLeft" data-unfold-duration={500}>
                        <span className="position-relative">
                          <i className="fas fa-bars h4"></i>
                        </span>
                      </a>
                    </div>
                    <div className="col-7 order-1 text-left px-0">
                      {/* User Info */}
                      <h1 className="h3 text-primary font-weight-normal mb-0">Welcome <span className="font-weight-semi-bold">{userContext.user.firstName}!</span></h1>
                      {/* End User Info */}
                    </div>
                  </div>
                  <div className="row">
                    <p className="d-block ">{userContext.user.email}</p>
                  </div>
                </div>
              </div>
              {/* End Breadcrumb Section */}
              <div className="container pt-6">

                <Switch>
                  <Route exact path="/" component={Properties} />
                  {/* <Route exact path="/" component={DashboardLanding} /> */}
                  <Route path="/createTicket" component={CreateTicket} />} />
                  <Route exact path="/properties" component={Properties} />} />
                  <Route path="/properties/create" render={(props) => <PropertyDetailForm submitText={"Add"}  {...props} />} />} />
                  <Route path="/properties/edit/:id" render={(props) => <PropertyDetailForm submitText={"Save"} {...props} />} />} } />
                  <Route path="/properties/detail/:id" component={PropDetail} />

                  <Route path="/account" component={Account} />
                  <Route path="/invite" component={Invite} />
                  <Route path="/signout" component={Signout} />} />
            {/* <Redirect from="/signout" to="/"></Redirect> */}
                  <Route component={Page404} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
        {/* End Content Section */}
      </main>
      {/* ========== END MAIN ========== */}

      {/* ========== SECONDARY CONTENTS ========== */}
      {/* Account Sidebar Navigation */}
      <aside id="sidebarContent" className="u-sidebar u-sidebar--left d-md-none" aria-labelledby="sidebarNavToggler" style={{ "backgroundColor": "#f0f2ff" }}>
        <div className="u-sidebar__scroller">
          <div className="u-sidebar__container">
            <div className="u-sidebar--account__footer-offset">
              {/* Toggle Button */}
              <div className="d-flex justify-content-between align-items-center pt-4 px-7">
                <button type="button" className="close ml-auto" aria-controls="sidebarContent" aria-haspopup="true" aria-expanded="false" data-unfold-event="click" data-unfold-hide-on-scroll="false" data-unfold-target="#sidebarContent" data-unfold-type="css-animation" data-unfold-animation-in="fadeInLeft" data-unfold-animation-out="fadeOutLeft" data-unfold-duration={500}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              {/* End Toggle Button */}
              <div>

                {/* Content */}

                <SideMenu></SideMenu>
              </div>

            </div>
          </div>
        </div>
      </aside>
      {/* End Account Sidebar Navigation */}

      {/* Go to Top */}
      <a className="js-go-to u-go-to" href="#" data-position="{&quot;bottom&quot;: 15, &quot;right&quot;: 15 }" data-type="fixed" data-offset-top={400} data-compensation="#header" data-show-effect="slideInUp" data-hide-effect="slideOutDown">
        <span className="fas fa-arrow-up u-go-to__inner" />
      </a>
    </div>

  );
}

function Dashboard() {
  const override = css`
    border-color: red;
`;
  return (
    <UserContextProvider>
      <UserContext.Consumer>
        {userContext => {
          return (
            <div>
              {userContext.loaded ?
                (!userContext.isSet ?
                  <div className="container justify-content-md-center pt-5 ">
                    <div className="pb-3">
                      <p className="h2">Tell us about yourself</p>
                    </div>
                    <div className="row justify-content-md-center">
                      <div className="p-3 col-lg-6">
                        <Account></Account>
                      </div>
                    </div>
                  </div>
                  :
                  <DashboardContent></DashboardContent>)
                :
                <div className="vh-100 d-flex align-items-center justify-content-center">
                <Loader 
                    sizeUnit={"px"}
                    size={20}
                    color={'#377dff'}
                    margin={20}
                  />
                </div>
              }
            </div>)
        }}
      </UserContext.Consumer>

    </UserContextProvider>
  );
}

export default Dashboard;
