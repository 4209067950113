import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { UserContext } from "./UserContext";

const Account = (props) => {
    const [profile, setProfile] = useState({ firstName: "", lastName: "", email: "", phoneNumber: "" });

    const userContext = useContext(UserContext)
    useEffect(() => {
        if (!userContext.user || Object.keys(userContext.user).length == 0) {
            // setTimeout(() => {
            //     setProfile({ firstName: "SandeepTest", lastName: "VermaTest", email: "deep_V_@hotmail.com", phoneNumber: "512-784-0706" });
            // }, 3000)
        }
        else {
            setProfile(userContext.user);
        }
    }, [userContext.user]);
    return (
        <AccountForm user={userContext.user} onSubmit={userContext.setUser} ></AccountForm>
    );
}
const AccountForm = withRouter((props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [profile, setProfile] = useState(props.user);
    useEffect(() => {
        setProfile(props.user);
    }, [props.user]);
    const onSubmit = async (event) => {

        event.preventDefault();

        if (event.target.checkValidity() === false)
            return;
        setIsLoading(true);

        // let newUserObj = {};
        // for (let targetIdx = 0; targetIdx < Object.keys(event.target).length; targetIdx++) {
        //     let target = event.target[targetIdx];
        //     if (!target)
        //         break;
        //     newUserObj[target.name] = target.value;
        // }

        props.onSubmit(profile).then(res => {
            setIsLoading(false);
            props.history.push('/properties');
        }, err => {
            //TODO : error handling
            alert('Error');
            console.error(err);
            setIsLoading(false);
        });

    }
    const handleProfileChange = (e) => {
        // debugger;
        setProfile({
            ...profile,
            [e.target.name]: e.target.value,
        });
    }
    const updatePhoneNumber = (val) => {
        setProfile({ ...profile, "phoneNumber": val });
    }
    return (
        < form className="js-validate" onSubmit={onSubmit} >
            <div className="row">
                {/* Input */}
                <div className="col-sm-6 mb-6">
                    <div className="js-form-message">
                        <label className="form-label">
                            Your first name
                            <span className="text-danger">*</span>
                        </label>

                        <input type="text" className="form-control" name="firstName" placeholder="Jack" aria-label="Jack" required data-msg="Please enter your first name." data-error-class="u-has-error" data-success-class="u-has-success" value={profile.firstName} onChange={handleProfileChange} />
                    </div>
                </div>
                {/* End Input */}
                {/* Input */}
                <div className="col-sm-6 mb-6">
                    <div className="js-form-message">
                        <label className="form-label">
                            Your last name
                  <span className="text-danger">*</span>
                        </label>
                        <input type="text" className="form-control" name="lastName" placeholder="Ryan" aria-label="Ryan" required data-msg="Please enter a your last name." data-error-class="u-has-error" data-success-class="u-has-success" value={profile.lastName} onChange={handleProfileChange} />
                    </div>
                </div>
                {/* End Input */}
                <div className="w-100" />
                {/* Input */}
                <div className="col-sm-6 mb-6">
                    <div className="js-form-message">
                        <label className="form-label">
                            Your Phone Number
                  <span className="text-danger">*</span>
                        </label>

                        <InputMask mask="999 999 9999" maskChar=" " type="text" className="form-control" name="phoneNumber" placeholder="555 555 5555" aria-label="1-800-643-4500" required data-msg="Please enter your phone number." data-error-class="u-has-error" data-success-class="u-has-success" value={profile.phoneNumber} onChange={handleProfileChange} />
                    </div>
                </div>
                {/* End Input */}
                {/* Input */}
                <div className="col-sm-6 mb-6">
                    <div className="js-form-message">
                        <span className="small">Contact support to change email</span>

                        <input type="email" disabled className="form-control  form-control-plaintext text-center" name="email" placeholder="jackryan@gmail.com" aria-label="jackryan@gmail.com" required data-msg="Please enter a valid email address." data-error-class="u-has-error" data-success-class="u-has-success" value={profile.email} onChange={handleProfileChange} />

                    </div>
                </div>
                {/* End Input */}
            </div>
            <div className="text-right">
                {isLoading ?

                    (<button class="btn btn-primary btn-wide transition-3d-hover mb-4 btn-pill" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>) :
                    (<button type="submit" className="btn btn-primary btn-wide transition-3d-hover mb-4" >Submit</button>)

                }
            </div>



        </form >

    )
});
export default Account;