import React, { useState, useEffect } from 'react';
// import logo from './logo.svg';
import './assets/css/App.css';
import './assets/vendor/font-awesome/css/fontawesome-all.min.css';
import './assets/vendor/animate.css/animate.min.css';
import './assets/vendor/hs-megamenu/src/hs.megamenu.css';
import './assets/vendor/fancybox/jquery.fancybox.css';
import './assets/vendor/slick-carousel/slick/slick.css';
import './assets/css/theme.css';
import Page404 from './components/Error404';
import Dashboard from './components/Dashboard/Dashboard';
import { Route, BrowserRouter as Router, Switch, Redirect, withRouter } from 'react-router-dom';

import awsconfig from './aws-exports';
import AWS from 'aws-sdk';

import { useAuth0 } from "./react-auth0-spa";
import PrivateRoute from './components/Auth/PrivateRoute';
import Loader from 'react-spinners/PropagateLoader';



const Routing = (props) => {

  return (
    <Router>
      <div>
        <Switch>
          {/* <Redirect exact path="/" to="/pub/login" /> */}
          <PrivateRoute path="/" component={Dashboard} />
          <Route path="/Error" component={Page404} />
          <Route component={Page404} />
        </Switch>
      </div>
    </Router>
  )
}
AWS.config.region = process.env.AWS_REGION;

const App = (props) => {
  const [user, setUser] = useState({ name: "Wilson", address: "123 Maple" });
  const { loading, getTokenSilently } = useAuth0();
  // const test = useAuth0();
  // debugger;
  if (loading) {
    return (

      <div className="vh-100 d-flex align-items-center justify-content-center">
      <Loader 
          sizeUnit={"px"}
          size={20}
          color={'#377dff'}
          margin={20}
      />
      </div>
    );
  }
  getTokenSilently().then((result) => {
    localStorage.setItem('jwt-token', result);
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:156701b6-11b8-468e-9c74-900e081d083a',
      Logins: {
        'cobble.auth0.com': result
      }
    });
  }, (error) => {
    console.log(error);
  });
  return (
    <div className="App bg-white">

      <Routing></Routing>
    </div>
  );
}

export default App;
