import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropertyCard from './PropertyCard';
import PropertyDetailForm from './PropertyDetailForm';
import { PropertyProvider } from './propertyProvider';
import Loader from 'react-spinners/PropagateLoader';

const PropertyDetailModal = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const save = () => {
        setIsLoading(true);



        setIsLoading(false);
    }
    const propertyUpdated = (property) => {

    }
    return (
        <div className="modal fade" id="propertyModal" tabIndex={-1} role="dialog" aria-labelledby="propertyModalLabel" aria-hidden="true" disabled={isLoading}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">

                    <header class="modal-header card-header bg-light py-3 px-1">
                        <div class="row w-100">
                            <div class="col-6">
                                <h4 class="h6 mb-0">Edit Property</h4>
                            </div>

                            <div class="col-6 text-right">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                        </div>
                    </header>
                    <div className="modal-body">
                        <PropertyDetailForm propertyUpdated={propertyUpdated}></PropertyDetailForm>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={save}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export const Properties = () => {
    // const [properties, setProperties] = useState([{ address: { street: "456 Main st", street2: "Apt 870", city: "Houston", state: "Tx", zip: "77006" }, id: "xyz", dateModified: Date.parse("1/1/2019"), tenant: { "name": "John", "phone": "111-111-1111", email: "abcd@gmail.com" } },
    // { address: { street: "123 Main st", street2: "Apt 145", city: "Houston", state: "Tx", zip: "77006" }, id: "abc", dateModified: Date.parse("10/15/2019"), tenant: { "name": "John", "phone": "111-111-1111", email: "person@gmail.com" } }]);
    const [properties, setProperties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const { getProperties, deleteProperty } = PropertyProvider();
    useEffect(() => {
        getProperties().then(new_props => {
            setProperties(new_props ? new_props.data : []);
            setIsLoading(false);
        });
    }, []);
    const deletePropertyWrapper = async (property) => {
        setIsEditing(true);
        try {
            let result =  await deleteProperty(property);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsEditing(false);
        }
    }
    return (<div>
        {isLoading ?
            <div className="vh-100 d-flex align-items-center justify-content-center">
                <Loader
                    sizeUnit={"px"}
                    size={20}
                    color={'#377dff'}
                    margin={20}
                />
            </div>
            :
            <div>
                {properties && properties.length > 0 ? (
                    <div>
                        <h2 class="text-left">Your houses</h2>

                        {/* Accordion */}
                        <div id="propertyList" className="accordion">

                            {properties.map((property, key) =>
                                <PropertyCard property={property} containerName={"prop-" + key} key={key} deleteProperty={deletePropertyWrapper} isEditing={isEditing}></PropertyCard>
                            )}
                        </div>
                    </div>)
                    :
                    <div>
                        <p className="h3">
                            Let's get started
                    </p>
                        <p className="h5">
                            Tell us about the property you want us to help manage
                    </p>
                    </div>}
                <div class="text-center pb-4 pt-5">
                    {/* <button className="btn btn-default btn-primary w-100 w-md-20 mt-3" data-toggle="modal" data-target="#propertyModal">Add a house</button> */}
                    <Link className="btn btn-default btn-primary w-100 w-md-20 mt-3" to="/properties/create">Add a house</Link>
                </div>
                <div>
                    <div>
                        {/* Modal */}
                        <PropertyDetailModal> </PropertyDetailModal>
                    </div>
                </div>
                {/* End Accordion */}
            </div>
        }
    </div>
    );
}