import React, { useState, useEffect } from 'react';
import { AccountProvider } from "./AccountProvider";
import { Account } from './Index';
import { withRouter } from 'react-router-dom';

import { useAuth0 } from "../../react-auth0-spa";

export const UserContext = React.createContext({
    displayName: '',
    user: null,
    loaded: false,
    isSet: false,
    setUser: (user) => { }
})

export const UserContextProvider = withRouter((props) => {
    const { user : auth0user } = useAuth0();
    const accountProvider = AccountProvider();
    
    const setUser = async (user) => {
        if(user.email != auth0user.email)
            throw "Can't change email address";
        return accountProvider.postAccountInfo(user).then((response) => {
            return updateAccountInfo();
        }, (err) => {
            //TODO: Error handling
            alert(err);
            throw err;
        })
    }
    const defaultUser = { firstName: "", lastName: "", email: auth0user.email, phoneNumber: "" };
    const initState = {
        user: defaultUser,
        displayName: '',
        loaded: false,
        setUser: setUser,
        isSet: false
    }

    const [state, setState] = useState(initState);
    const updateAccountInfo = () => {
        return accountProvider.getAccountInfo().then((account) => {
            let newState = { ...state, user: account};

            if (account && Object.keys(account).length > 0 && account.firstName && account.lastName && account.email && account.phoneNumber)  {
                newState['displayName'] = account.firstName + " " + account.lastName;
                newState['isSet'] = true;
            }
            else{
                newState['displayName'] = '';
                newState['isSet'] = false;
                newState['user'] = defaultUser;
            }
            newState['loaded'] = true;
            setState(newState);
        });
    }

    useEffect(() => {
        if (!state.isSet) {
            updateAccountInfo();
        }
    }, [props.history.location.pathname]);
    return (
        <UserContext.Provider value={state}>
            {props.children}
        </UserContext.Provider>
    )
});