import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';


class SearchAddress extends Component {
    // Define Constructor
    constructor(props) {
        super(props);
        // Declare State
        this.state = {
            curText : ''
        };
        // Bind Functions
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        props.history.listen((location, action) => {
            this.onRouteChanged();
        });
    }
    componentDidMount() {
        // window.addEventListener('load', this.handleScriptLoad);
        this.handleScriptLoad();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState == this.state)
            return;
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }

    }
    onRouteChanged(){
        if(this.searchBox)
            this.searchBox.value = "";
    }
    render() {
        return (<div id="pac-container" className="input-group flex-fill">
            <input className="form-control" id="pac-input" type="text" name="Address"  ref={el => this.searchBox = el} placeholder={this.props.placeholder || "Enter a city, zip or address"} aria-label="Address" required data-msg="Name must contain only letters." />
        </div>);
    }
    handleScriptLoad() {
        // var defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(29.59169, -95.570489), new google.maps.LatLng(29.946627, -95.135289));
        var options = {
            //   this doesn't seem to work
            // bounds: defaultBounds,
            // types: ['(cities)']
        };
        // // Declare Options For Autocomplete
        // var options = {
        // types: ['(cities)'],
        // };
        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('pac-input'), options);
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    }
    handlePlaceSelect() {
        // Extract City From Address Object
        let addressObject = this.autocomplete.getPlace();
        let streetNumber = addressObject.address_components.filter((item) => {return item.types.includes("street_number")})[0].long_name;
        let streetName = addressObject.address_components.filter((item) => {return item.types.includes("route")})[0].long_name;
        let postalCode = addressObject.address_components.filter((item) => {return item.types.includes("postal_code")})[0].long_name;
        let state = addressObject.address_components.filter((item) => {return item.types.includes("administrative_area_level_1")})[0].long_name;
        let city = addressObject.address_components.filter((item) => {return item.types.includes("locality")})[0].long_name; // addressObject.vicinity
        // Check if address is valid
        if (streetName) {
            if (this.props.placeChanged)
                this.props.placeChanged({
                    streetNumber: streetNumber, 
                    streetName: streetName,
                    city: city,
                    postalCode: postalCode,
                    state: state,
                    query: addressObject.formatted_address,
                    raw: addressObject
                });
        }
    }
}
export default withRouter(SearchAddress);