import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
export const PropDetail = (props) => {
    const [property, setProperty] = useState({});//useState({ address: "123 Main st", tenant: { "name": "John", "phone": "111-111-1111" } });
    useEffect(() => {
        setProperty(props.location.property);
        console.log("property -----\n ");
        console.log(props.location.property);
        return () => {
            
        };
    }, [props.location.property]);
    return (
        <div>
            <h1>Properties</h1>
            {property.address}
            <div class="card">
                <h3>{props.location.property.address}</h3> 
                <h3>{props.location.property.tenant.name}</h3>
            </div>
        </div>
    );
}