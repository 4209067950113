import React from 'react';

const Page404 = ({ location }) => (

  <div class="d-lg-flex">
    <div class="container d-lg-flex align-items-lg-center min-height-lg-100vh space-top-0 pt-0 mt-0 space-bottom-2 space-lg-0">
      <div class="w-100 mx-auto">
        <figure id="SVGmaintenanceMode" class=" mb-5 ie-maintenance-mode">
          <span role="img" style={{ fontSize: "100px" }}>&#128561;</span>
        </figure>

        <div class="w-md-80 w-lg-50 text-center mx-md-auto">
          <div class="mb-5">
            <h1 class="h2 font-weight-normal">We're so embarrassed.</h1>
            <p>Something strange happened, let us know if you see me again <a href="mailto:support@cobble.ai">support@cobble.ai</a></p>
          </div>

          <div class="pb-3">
            <a class="btn btn-primary transition-3d-hover w-25 " href="/">Home</a>
          </div>
          <div class="">
            <button class="btn btn-primary transition-3d-hover w-25" onClick={() => { window.history.back(); }}>Back</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Page404;