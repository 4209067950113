import axios from 'axios';
import { useAuth0 } from "../../react-auth0-spa";

export const AccountProvider = () => {
    const { getTokenSilently } = useAuth0();
    
    const getAccountInfo = async () => {
        const token = await getTokenSilently();
        // for later: https://medium.com/@trekinbami/using-environment-variables-in-react-6b0a99d83cf5
        let url = `${process.env.REACT_APP_SERVER_URL}/account`;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(res => {
                console.log("success!");
                if (res && res.data){
                    return res.data.value;

                }
                return null;
            }, error => {
                console.log(error);
                return error.value;
            });
    };
    const postAccountInfo = async (account) => {
        const token = await getTokenSilently();
        // for later: https://medium.com/@trekinbami/using-environment-variables-in-react-6b0a99d83cf5
        let url = `${process.env.REACT_APP_SERVER_URL}/account`;
        return axios.post(url, { account }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(res => {
                console.log("success!");
                return res;
            }, error => {
                console.error(error);
                if (error.response){
                    console.log(error.response.data);
                    throw error.response.data;
                }
                throw error;
            });
    };
    return {
        getAccountInfo,
        postAccountInfo
    };
};
